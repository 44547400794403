import React from 'react';
import { useState } from 'react';
import './App.css';

const App = () => {
  const [activeTab, setActiveTab] = useState('home');

  const renderContent = () => {
    switch(activeTab) {
      case 'home':
        return (
          <div className="content">
            <h2>Welcome to My Personal Website</h2>
            <p>Hi, I'm Alden Bodak. I'm a [Your Profession] passionate about [Your Interests].</p>
          </div>
        );
      case 'about':
        return (
          <div className="content">
            <h2>About Me</h2>
            <p>I have X years of experience in [Your Field]. My expertise includes [Your Skills].</p>
          </div>
        );
      case 'projects':
        return (
          <div className="content">
            <h2>My Projects</h2>
            <ul>
              <li>Project 1: [Brief Description]</li>
              <li>Project 2: [Brief Description]</li>
              <li>Project 3: [Brief Description]</li>
            </ul>
          </div>
        );
      case 'contact':
        return (
          <div className="content">
            <h2>Contact Me</h2>
            <p>Email: your.email@example.com</p>
            <p>LinkedIn: [Your LinkedIn Profile]</p>
            <p>GitHub: [Your GitHub Profile]</p>
          </div>
        );
      default:
        return <div>404 - Not Found</div>;
    }
  };

  return (
    <div className="app">
      <header>
        <h1>Alden Bodak</h1>
        <nav>
          <button onClick={() => setActiveTab('home')} className={activeTab === 'home' ? 'active' : ''}>Home</button>
          <button onClick={() => setActiveTab('about')} className={activeTab === 'about' ? 'active' : ''}>About</button>
          <button onClick={() => setActiveTab('projects')} className={activeTab === 'projects' ? 'active' : ''}>Projects</button>
          <button onClick={() => setActiveTab('contact')} className={activeTab === 'contact' ? 'active' : ''}>Contact</button>
        </nav>
      </header>
      <main>
        {renderContent()}
      </main>
      <footer>
        <p>© 2024 Alden Bodak. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default App;
